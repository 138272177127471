exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-en-blog-index-jsx": () => import("./../../../src/pages/en/blog/index.jsx" /* webpackChunkName: "component---src-pages-en-blog-index-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ru-blog-index-jsx": () => import("./../../../src/pages/ru/blog/index.jsx" /* webpackChunkName: "component---src-pages-ru-blog-index-jsx" */),
  "component---src-pages-ru-index-jsx": () => import("./../../../src/pages/ru/index.jsx" /* webpackChunkName: "component---src-pages-ru-index-jsx" */),
  "component---src-updated-components-en-templates-article-page-article-page-template-jsx": () => import("./../../../src/updated/components/en/templates/article-page/article-page-template.jsx" /* webpackChunkName: "component---src-updated-components-en-templates-article-page-article-page-template-jsx" */),
  "component---src-updated-components-ru-templates-article-page-article-page-template-jsx": () => import("./../../../src/updated/components/ru/templates/article-page/article-page-template.jsx" /* webpackChunkName: "component---src-updated-components-ru-templates-article-page-article-page-template-jsx" */)
}

